<template>
    <section class="work">
      <h1>Coming soon</h1>
    </section>
</template>

<script>
export default {
  name: 'ComingSoon',
};
</script>

<style scoped>
.work{
  min-height: 75vh;
  display: flex;
  background: #ffffff; 
  padding: 20px 20px;
  display: flex;
  width: 100%; 
}

.work h1{
  font-size: 6rem;
  color: rgb(0, 0, 0);
  padding: 20px 20px;
  align-self: center;
  display: flex;
}

</style>

