<template>
    <section id="services" class="services-section">
      <h2><i class="fas fa-concierge-bell"></i> Our Services</h2>
      <div class="services-container">
        <div class="service-card">
          <h3><i class="fas fa-home"></i> Residential Moving</h3>
          <p>Effortless relocation for your home.</p>
        </div>
        <div class="service-card">
          <h3><i class="fas fa-building"></i> Office Relocation</h3>
          <p>Seamless transitions for your business.</p>
        </div>
        <div class="service-card">
          <h3><i class="fas fa-box"></i> Packing Services</h3>
          <p>Secure packing for peace of mind.</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ServicesSection',
  };
  </script>
  
  <style scoped>
  .services-section {
    padding: 40px 20px;
    max-width: 100vw;
    margin: auto;
    text-align: center;
    background-color: #1e4e9a; /* Dark blue */
  }
  
  h2 {
    font-size: 2rem;
    color: #ffffff;
  }
  
  .services-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .service-card {
    flex: 1;
    background: rgb(244, 244, 244);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    color: #163e7f;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #000000;
  }
  </style>
  